<script setup>
import {useBaseStore} from "~/stores/base";
import _ from "lodash"
import {filterSearch, filterSearchBefore} from "~/api/api.filter";
import {Loading} from "@element-plus/icons-vue";
import {PATH_SEARCH} from "~/utils/constants";
import {closeAllDrawers} from "~/utils/common";
import {tiktokPixelTrack} from "~/utils/tiktok-track";

const BNTOMessage = useNuxtApp().$BNTOMessage
const router = useRouter()

const searchInputVisible = ref(false)
const popupVisible = ref(false)
const inputRef = ref(null)
const isLoading = ref(false)
const trending = ref([])
const keywords = ref("")
const searchCount = ref(0)
const searchSuggestions = ref([])
const searchHistory = ref([])
const inputFocused = ref(false)

const debounce = _.debounce((params, func) => {
  func(params);
}, 200);

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

/** watch **/
watch(inputFocused, (focused) => {
  debounce({}, () => {
    if (focused) {
      // 为了手机端
      if (isMobile.value) {
        handleSearchActive()
      }
    }
    if (!focused) {
      if (!isMobile.value) {
        handleSearchInactive()
      }
    }
  })
})
watch(keywords, () => {
  isLoading.value = true
  debounce({}, () => {
    handleSearch()
  })
})

/** methods **/
const handleSearchActive = async () => {
  // 获取当前路径，但去掉 hash 部分
  router.replace({
    path: router.currentRoute.value.path,
    query: router.currentRoute.value.query, // 保留查询参数
    hash: '' // 移除 hash
  });
  /** 收起其他Drawer **/
  closeAllDrawers()

  searchInputVisible.value = true
  // Wait for the next DOM update cycle
  await nextTick();
  inputRef.value.focus()

  if (isMobile.value) {
    // 防止header滚上去
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    popupVisible.value = true
  } else {
    setTimeout(() => {
      popupVisible.value = true
    }, 200)
  }

}
const handleSearchInactive = async () => {
  searchInputVisible.value = false
  if (inputRef.value) inputRef.value.blur()
  popupVisible.value = false
}
const getTrending = async () => {
  try {
    const res = await filterSearchBefore()
    // console.log("filterSearchBefore", res.result)
    trending.value = res.result.trending
  } catch (e) {
    console.error(e)
  }
}
const getHistory = () => {
  const searchHistoryFromStorage = JSON.parse(localStorage.getItem("SEARCH_HISTORY"))
  // console.log("searchHistoryFromStorage", searchHistoryFromStorage)
  searchHistory.value = searchHistoryFromStorage || []
}
const setHistory = () => {
  if (!keywords.value) return
  if (keywords.value === searchHistory.value[0]) return
  searchHistory.value = searchHistory.value.filter(item => item !== keywords.value)
  searchHistory.value.unshift(keywords.value)
  searchHistory.value = searchHistory.value.slice(0, 5)
  localStorage.setItem("SEARCH_HISTORY", JSON.stringify(searchHistory.value))
}
const clearHistory = async () => {
  await handleSearchActive()
  searchHistory.value = []
  localStorage.setItem("SEARCH_HISTORY", JSON.stringify(searchHistory.value))
}
const handleHistoryClick = async (history) => {
  await handleSearchActive()
  keywords.value = history
}
const handleSearch = async () => {
  try {
    if (!keywords.value) {
      searchCount.value = 0
      searchSuggestions.value = []
      isLoading.value = false
      return
    }
    setHistory()
    const res = await filterSearch({search: keywords.value})
    isLoading.value = false
    console.log("filterSearch", res.result)
    searchCount.value = res.result.searchCount
    searchSuggestions.value = res.result.searchSuggestions
    trending.value = res.result.trending
    tiktokPixelTrack("Search", {keywords: keywords.value})
  } catch (e) {
    isLoading.value = false
  }
}
const handleClear = async () => {
  await handleSearchActive()
  keywords.value = ''
}
const handleEnter = async () => {
  if (searchSuggestions.value.length === 1) {
    const first = searchSuggestions.value[0]
    await router.push(first.link)
    await handleSearchInactive()
  }
}

onMounted(() => {
  getTrending()
  getHistory()
  window.addEventListener('scroll', () => {
    handleSearchInactive()
  })
})
</script>

<template>
  <div class="module-search-mobile" v-if="isMobile" @touchmove.stop>
    <!-- ipnut -->
    <div class="input-search">
      <img class="icon" src="@/assets/img/icon-glass.svg" alt="icon">
      <input
        ref="inputRef"
        type="text"
        v-model.trim="keywords"
        placeholder="Looking for something?"
        @focus="inputFocused = true"
        @blur="inputFocused = false"
        @keydown.enter="handleEnter"
      >
      <img v-if="keywords" class="clear" src="@/assets/img/icon-close-black.svg" alt="icon" @click="handleClear">
    </div>
    <div class="btn-secondary btn-exit" v-if="popupVisible" @click="handleSearchInactive">exit</div>
    <!-- popup -->
    <transition name="fade" mode="out-in">
      <div
        class="popup-search"
        v-if="popupVisible"
      >
        <!-- loading -->
        <div class="block" v-if="isLoading">
          <div class="loading">
            <el-icon class="is-loading" color="#292929" size="16">
              <Loading/>
            </el-icon>
          </div>
        </div>
        <!-- loading finish -->
        <template v-else>
          <!-- has keywords -->
          <template v-if="keywords">
            <!-- has results -->
            <template v-if="keywords && searchCount !== 0 || searchSuggestions.length !== 0">
              <!-- product result -->
              <div class="block" v-if="searchCount">
                <nuxt-link :to="`${PATH_SEARCH}/${keywords}?title=${searchCount}%20${searchCount > 1 ? 'results' : 'result'}%20for%20%22${keywords}%22`" class="tips"><span class="bold">"{{keywords}}"</span>&nbsp;&nbsp;({{searchCount}} {{searchCount > 1 ? 'results' : 'result'}})</nuxt-link>
              </div>
              <!-- search suggestions -->
              <div class="block" v-if="searchSuggestions.length">
                <div class="title">search suggestions</div>
                <div class="content">
                  <nuxt-link
                    :to="item.link"
                    class="cell"
                    v-for="item in searchSuggestions"
                  >
                    {{ item.name }}<span class="highlight">{{item.hitIt}}</span>
                  </nuxt-link>
                </div>
              </div>
            </template>
            <!-- no results -->
            <div class="block" v-else>
              <div class="tips"><span class="bold">"{{keywords}}"</span>&nbsp;&nbsp;Sorry, we couldn’t find any results</div>
            </div>
          </template>
          <!-- no keywords -->
          <template v-else>
            <!-- show nothing -->
          </template>
        </template>
        <!-- trending -->
        <div class="block" v-if="trending.length">
          <div class="title">trending</div>
          <div class="content">
            <nuxt-link
              :to="item.link"
              class="cell"
              v-for="item in trending"
            >
              {{ item.title }}
            </nuxt-link>
          </div>
        </div>
        <!-- recently searched -->
        <div class="block" v-if="searchHistory.length">
          <div class="title">
            <span class="left">recently searched</span>
            <div class="right" @click="clearHistory">clear <img src="@/assets/img/icon-close-black.svg" alt=""></div>
          </div>
          <div class="content">
            <div class="cell" v-for="item in searchHistory" @click="handleHistoryClick(item)">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div
    class="module-search-desktop"
    :class="{'module-search-desktop-active': searchInputVisible, 'module-search-desktop-inactive': !searchInputVisible}"
    v-else
  >
    <!-- 展开 -->
    <div class="input-search" v-if="searchInputVisible">
      <img class="icon" src="@/assets/img/icon-glass.svg" alt="icon">
      <input
        ref="inputRef"
        type="text"
        v-model.trim="keywords"
        @focus="inputFocused = true"
        @blur="inputFocused = false"
        @keydown.enter="handleEnter"
      >
      <img v-if="keywords" class="clear" src="@/assets/img/icon-close-black.svg" alt="icon" @click="handleClear">
    </div>
    <!-- 收起 -->
    <div class="button-search" @click="handleSearchActive" v-else>
      <img class="icon" src="@/assets/img/icon-glass.svg" alt="icon">
      {{ $t('headerSearch') }}
    </div>
    <!-- popup -->
    <transition name="fade" mode="out-in">
      <div
        class="popup-search"
        :class="{'popup-search-active': popupVisible, 'popup-search-inactive': !popupVisible}"
        v-if="popupVisible"
      >
        <!-- loading -->
        <div class="block" v-if="isLoading">
          <div class="loading">
            <el-icon class="is-loading" color="#292929" size="16">
              <Loading/>
            </el-icon>
          </div>
        </div>
        <!-- loading finish -->
        <template v-else>
          <!-- has keywords -->
          <template v-if="keywords">
            <!-- has results -->
            <template v-if="searchCount !== 0 || searchSuggestions.length !== 0">
              <!-- product result -->
              <div class="block" v-if="searchCount">
                <nuxt-link :to="`${PATH_SEARCH}/${keywords}?title=${searchCount}%20${searchCount > 1 ? 'results' : 'result'}%20for%20%22${keywords}%22`" class="tips link"><span class="bold">"{{keywords}}"</span>&nbsp;&nbsp;({{searchCount}} {{ searchCount > 1 ? 'results' : 'result' }})</nuxt-link>
              </div>
              <!-- search suggestions -->
              <div class="block" v-if="searchSuggestions.length">
                <div class="title">search suggestions</div>
                <div class="content">
                  <nuxt-link
                    :to="item.link"
                    class="cell"
                    v-for="item in searchSuggestions"
                  >
                    {{ item.name }}<span class="highlight">{{item.hitIt}}</span>
                  </nuxt-link>
                </div>
              </div>
            </template>
            <!-- no results -->
            <div class="block" v-else>
              <div class="tips"><span class="bold">"{{keywords}}"</span>&nbsp;&nbsp;Sorry, we couldn’t find any results</div>
            </div>
          </template>
          <!-- no keywords -->
          <template v-else>
            <!-- show nothing -->
          </template>
        </template>
        <!-- trending -->
        <div class="block" v-if="trending.length">
          <div class="title">trending</div>
          <div class="content">
            <nuxt-link
              :to="item.link"
              class="cell"
              v-for="item in trending"
            >
              {{ item.title }}
            </nuxt-link>
          </div>
        </div>
        <!-- recently searched -->
        <div class="block" v-if="searchHistory.length">
          <div class="title">
            <span class="left">recently searched</span>
            <div class="right" @click="clearHistory">clear <img src="@/assets/img/icon-close-black.svg" alt=""></div>
          </div>
          <div class="content">
            <div class="cell" v-for="item in searchHistory" @click="handleHistoryClick(item)">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-search-mobile {
  box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
  width: 100%;
  position: relative;
  z-index: 1;

  display: flex;
  gap: 8px;

  .input-search {
    background-color: white;
    padding: 8px 12px;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      flex-shrink: 0;
    }

    input {
      width: 100%;
    }

    .clear {
      width: 20px;
      height: 20px;
      margin-left: 8px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }

  .btn-exit {
    width: 60px;
    height: 36px;
    min-height: 36px;
    flex-shrink: 0;
    /* Button/small */
    font-family: "Druk Wide Cy";
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px; /* 111.111% */
    text-transform: uppercase;
  }

  .popup-search {
    height: calc(100vh - $mobile-header-height - 52px);
    overflow-y: scroll;
    width: 100%;
    transition: all .2s ease-in-out;

    position: fixed;
    top: calc($mobile-header-height + 52px);
    left: 0;

    background-color: white;

    padding: 16px 16px 500px 16px;

    .block {
      margin-top: 16px;

      .title {
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;

        display: flex;
        justify-content: space-between;

        .right {
          /* Button/small-underlined */
          font-family: "Druk Wide Cy";
          font-size: 9px;
          font-style: normal;
          font-weight: 500;
          line-height: 10px; /* 111.111% */
          text-decoration-line: underline;
          text-transform: uppercase;

          display: flex;
          align-items: center;

          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
            margin-left: 4px;
            margin-bottom: 1px;
          }
        }
      }

      .content {
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        .cell {
          margin: 5px 0;
          padding: 0 8px;
          /* Text sm/Regular */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px; /* 142.857% */

          cursor: pointer;

          .highlight {
            font-family: "TWK Lausanne";
            font-size: 12px;
            font-style: normal;
            font-weight: 650;
            line-height: 20px;
          }
        }
      }

      .tips {
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
      }

      .loading {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .bold {
        font-family: "TWK Lausanne";
        font-size: 16px;
        font-style: normal;
        font-weight: 650;
        line-height: 24px; /* 150% */
      }
    }
  }
}

.module-search-desktop {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  border: 0.5px solid $color-BNTO-beige-dark;
  padding: 4px 12px;
  transition: all .2s ease-in-out;

  .button-search {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    /* Text sm/Regular */
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 142.857% */

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      flex-shrink: 0;
    }
  }

  .input-search {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      flex-shrink: 0;
    }

    input {
      width: 100%;
    }

    .clear {
      width: 24px;
      height: 24px;
      margin-left: 4px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }

  .popup-search {
    max-height: 500px;
    overflow-y: scroll;
    width: 97.33px;
    transition: all .2s ease-in-out;

    /* Shadow/lg */
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);

    position: absolute;
    top: 36px;
    left: -1.5px;

    background-color: white;

    padding: 16px;

    .block {
      margin-top: 16px;

      .title {
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;

        display: flex;
        justify-content: space-between;

        .right {
          /* Button/small-underlined */
          font-family: "Druk Wide Cy";
          font-size: 9px;
          font-style: normal;
          font-weight: 500;
          line-height: 10px; /* 111.111% */
          text-decoration-line: underline;
          text-transform: uppercase;

          display: flex;
          align-items: center;

          cursor: pointer;

          img {
            width: 20px;
            height: 20px;
            margin-left: 4px;
            margin-bottom: 1px;
          }
        }
      }

      .content {
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        .cell {
          margin: 5px 0;
          padding: 0 8px;
          /* Text sm/Regular */
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px; /* 142.857% */

          cursor: pointer;

          .highlight {
            font-family: "TWK Lausanne";
            font-size: 12px;
            font-style: normal;
            font-weight: 650;
            line-height: 20px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .tips {
        /* Text xs/Regular */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
      }

      .link {
        &:hover {
          text-decoration: underline;
        }
      }

      .loading {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .bold {
        font-family: "TWK Lausanne";
        font-size: 16px;
        font-style: normal;
        font-weight: 650;
        line-height: 24px; /* 150% */
      }
    }
  }

  .popup-search-inactive {
    width: 97.33px;
  }

  .popup-search-active {
    width: calc(100vw - 15px * 2 - 140px - 88.54px - 106.7px - 10px - 106.19px - 100.7px - 114.84px - 18px);
    min-width: calc($content-1280-width - 15px * 2 - 140px - 88.54px - 106.7px - 10px - 106.19px - 100.7px - 114.84px - 18px);
  }
}

.module-search-desktop-inactive {
  width: 97.33px;
}

.module-search-desktop-active {
  border: 1.5px solid $color-BNTO-beige-dark;
  width: calc(100vw - 15px * 2 - 140px - 88.54px - 106.7px - 10px - 106.19px - 100.7px - 114.84px - 18px);
  min-width: calc($content-1280-width - 15px * 2 - 140px - 88.54px - 106.7px - 10px - 106.19px - 100.7px - 114.84px - 18px);
}
</style>
