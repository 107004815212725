import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

// https://mobiverse.feishu.cn/docx/SRD9dFZ9voXfO5xOM2Pc2V64n9k
// brandId	否	String	品牌ID（用于brand页面）	1691239759832875010
// category	否	String	分类（用于wishlist页面）	wishlist
// collection	否	String	产品系列（用于new-arrivals、collection页面等）	on-sale、new-arrivals
// search	否	String	搜索关键词（用于search页面）
export function filterInfo({brandId, category, collection, search}) {
  return omniFetch({
    url: config.v1Path + config.filterInfoPath,
    method: "get",
    params: {brandId, category, collection, search}
  })
}

export function filterSearchBefore() {
  return omniFetch({
    url: config.v1Path + config.filterSearchBeforePath,
    method: "get",
    params: {}
  })
}

export function filterSearch({search}) {
  return omniFetch({
    url: config.v1Path + config.filterSearchPath,
    method: "get",
    params: {search}
  })
}
